import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageDE from './assets/content/de.json';
import languageEN from './assets/content/en.json';
import languageTR from './assets/content/tr.json';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            de: languageDE,
            en: languageEN,
            tr: languageTR
        },
        lng: "en",
        fallbackLng: "en",
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });

export default i18n;