import React, { useEffect, useRef, useState } from 'react';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from "@microsoft/signalr";
import { RTCEndpoint } from '../env';
import 'bootstrap/dist/css/bootstrap.min.css';

const WebRTCClient: React.FC = () => {
    const [screenSharing, setScreenSharing] = useState(false);

    const handleScreenShareToggle = async (enabled: boolean) => {
        setScreenSharing(enabled);
        // if (enabled) {
        //     alert('Ekran paylaşımı başlatılıyor. Lütfen mevcut sekmede kalmaya dikkat edin.');
        // }

        if (!enabled) {
            // Stop screen sharing
            const senders = peerConnection.current?.getSenders();
            senders?.forEach((sender) => {
                if (sender.track?.kind === 'video' && sender.track?.label.includes('screen')) {
                    sender.track.stop();
                    peerConnection.current?.removeTrack(sender);
                }
            });
            // Revert to webcam stream
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                stream.getTracks().forEach((track) => {
                    if (track.kind === 'audio' && !track.label.includes('local')) {
                        track.enabled = audioEnabled; // Enable or disable audio based on audioEnabled state
                    }
                    if (track.kind === 'video' || (track.kind === 'audio' && !track.label.includes('local'))) {
                        peerConnection.current?.addTrack(track, stream);
                    }
                    
                });
                // Notify other participants about the stream change
                peerConnection.current?.getSenders().forEach((sender) => {
                    if (sender.track?.kind === 'video') {
                        sender.replaceTrack(stream.getVideoTracks()[0]);
                    }
                });
            } catch (error) {
                console.error('Error accessing media devices.', error);
            }
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                stream.getTracks().forEach((track) => {
                    peerConnection.current?.addTrack(track, stream);
                });
                // Notify other participants about the stream change
                peerConnection.current?.getSenders().forEach((sender) => {
                    if (sender.track?.kind === 'video') {
                        sender.replaceTrack(stream.getVideoTracks()[0]);
                    }
                });
            } catch (error) {
                console.error('Error accessing media devices.', error);
            }
        }

        if (enabled) {
            alert('Ekran paylaşımı başlatılıyor. Lütfen mevcut sekmede kalmaya dikkat edin.');
        }
        if (!enabled) {
            
        }
        if (enabled) {
            try {
                const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
                localStream.current = screenStream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = screenStream;
                }
                screenStream.getTracks().forEach((track) => {
                    peerConnection.current?.addTrack(track, screenStream);
                });
            } catch (error) {
                console.error('Error accessing display media.', error);
                setScreenSharing(false);
            }
        } else {
            // Stop screen sharing
            const senders = peerConnection.current?.getSenders();
            senders?.forEach((sender) => {
                if (sender.track?.kind === 'video' && sender.track?.label.includes('screen')) {
                    sender.track.stop();
                    peerConnection.current?.removeTrack(sender);
                }
            });
            // Revert to webcam stream
            if (localStream.current) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                stream.getTracks().forEach((track) => {
                    peerConnection.current?.addTrack(track, stream);
                });
                // Notify other participants about the stream change
                peerConnection.current?.getSenders().forEach((sender) => {
                    if (sender.track?.kind === 'video') {
                        sender.replaceTrack(stream.getVideoTracks()[0]);
                    }
                });
            }
            if (localStream.current) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                stream.getTracks().forEach((track) => {
                    peerConnection.current?.addTrack(track, stream);
                });
                // Notify other participants about the stream change
                peerConnection.current?.getSenders().forEach((sender) => {
                    if (sender.track?.kind === 'video') {
                        sender.replaceTrack(stream.getVideoTracks()[0]);
                    }
                });
            }
            if (localStream.current) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                }
                stream.getTracks().forEach((track) => {
                    peerConnection.current?.addTrack(track, stream);
                });
            }
        }
    };
    const peerConnection = useRef<RTCPeerConnection | null>(null);
    const remoteStreams = useRef<Map<string, MediaStream>>(new Map());
    const localStream = useRef<MediaStream | null>(null);
    const localVideoRef = useRef<HTMLVideoElement | null>(null);
    const connection = useRef<HubConnection | null>(null);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [remoteVideos, setRemoteVideos] = useState<JSX.Element[]>([]);

    useEffect(() => {
        // SignalR connection setup
        let url = `${RTCEndpoint}/hubs/audio`;
        const options: IHttpConnectionOptions = {
            headers: { 'deviceId': navigator.userAgent ?? 'unknown-device-id' },
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        };
        connection.current = new HubConnectionBuilder()
            .withUrl(url, options)  // SignalR server URL
            .withAutomaticReconnect()
            .build();

        // WebRTC configuration
        const config: RTCConfiguration = {
            iceServers: [] // No ICE servers, using local network
        };

        // Start SignalR connection
        connection.current.start().then(async () => {
            console.log("SignalR connection established");

            // Create a new RTCPeerConnection
            peerConnection.current = new RTCPeerConnection(config);

            // Get user media (webcam and microphone)
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: audioEnabled });
                localStream.current = stream;
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = localStream.current;
                }
                stream.getTracks().forEach((track) => {
                if (track.kind === 'audio') {
                    track.enabled = audioEnabled; // Enable or disable audio based on audioEnabled state
                } peerConnection.current?.addTrack(track, stream);
            });
            } catch (error) {
                console.error("Error accessing media devices.", error);
                return;
            }

            // Set up ICE candidate exchange
            peerConnection.current.onicecandidate = (event) => {
                if (event.candidate) {
                    connection.current?.invoke("SendIceCandidate", JSON.stringify(event.candidate));
                }
            };

            // Handle remote track added for multiple viewers (1 to N broadcasting)
            peerConnection.current.ontrack = (event) => {
                const streamId = event.streams[0].id;
                if (!remoteStreams.current.has(streamId)) {
                    remoteStreams.current.set(streamId, event.streams[0]);
                    setRemoteVideos((prevVideos) => [
                        ...prevVideos,
                        <div className="remote-video" key={streamId} style={{ position: 'absolute', bottom: '10px', right: '10px', width: '200px', height: '150px', border: '2px solid white', zIndex: 10 }}>
                            <video
                                ref={(videoElement) => {
                                    if (videoElement) {
                                        videoElement.srcObject = event.streams[0];
                                    }
                                }}
                                autoPlay
                                playsInline
                                
                                controls={false}
                                style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
                            />
                        </div>
                    ]);
                }
            };

            // Handle StartWebRTCSession from server
            connection.current?.on("StartWebRTCSession", async () => {
                console.log("Starting WebRTC session");
                const offer = await peerConnection.current?.createOffer();
                if (offer) {
                    await peerConnection.current?.setLocalDescription(offer);
                    connection.current?.invoke("SendOffer", JSON.stringify(offer));
                }
            });

            // Handle receiving an SDP offer
            connection.current?.on("ReceiveOffer", async (offer: string) => {
                console.log("Received SDP Offer:", offer);
                await peerConnection.current?.setRemoteDescription(new RTCSessionDescription(JSON.parse(offer)));
                const answer = await peerConnection.current?.createAnswer();
                if (answer) {
                    await peerConnection.current?.setLocalDescription(answer);
                    connection.current?.invoke("SendAnswer", JSON.stringify(answer));
                }
            });

            // Handle receiving an SDP answer
            connection.current?.on("ReceiveAnswer", async (answer: string) => {
                console.log("Received SDP Answer:", answer);
                await peerConnection.current?.setRemoteDescription(new RTCSessionDescription(JSON.parse(answer)));
            });

            // Handle receiving an ICE candidate
            connection.current?.on("ReceiveIceCandidate", async (candidate: string) => {
                console.log("Received ICE Candidate:", candidate);
                try {
                    await peerConnection.current?.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)));
                } catch (error) {
                    console.error("Error adding received ICE candidate", error);
                }
            });
        }).catch(err => console.error("SignalR connection failed:", err));

        // Cleanup on component unmount
        return () => {
            if (connection.current) {
                connection.current.stop();
            }
            if (peerConnection.current) {
                peerConnection.current.close();
            }
            remoteStreams.current.forEach((_, streamId) => {
                const videoElement = document.querySelector(`video[srcObjectId="${streamId}"]`);
                if (videoElement) {
                    videoElement.remove();
                }
            });
        };
    }, [audioEnabled, videoEnabled]);

    const handleAudioToggle = () => {
        setAudioEnabled((prev) => {
            const enabled = !prev;
            localStream.current?.getAudioTracks().forEach((track) => {
                track.enabled = enabled;
            });
            return enabled;
        });
    };

    const handleVideoToggle = () => {
        setVideoEnabled((prev) => {
            const enabled = !prev;
            if (localVideoRef.current) {
                if (enabled) {
                    // Show video stream
                    localVideoRef.current.srcObject = localStream.current;
                } else {
                    // Hide video stream but keep black screen
                    localVideoRef.current.srcObject = null;
                    localVideoRef.current.style.backgroundColor = 'black';
                    localVideoRef.current.style.width = '100vw';
                    localVideoRef.current.style.height = '100vh';
                    localVideoRef.current.style.width = '100vw';
                    localVideoRef.current.style.height = '100vh';
                     // Set a default height for the black screen
                }
            }
            return enabled;
        });
    };

    return (
        <div className="w-100 h-100 position-absolute">  
            <div className="row">
                <div className="col">
                    <video ref={localVideoRef} autoPlay playsInline muted style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, backgroundColor: 'black' }} />
                    <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '8px' }}>
                        
                        
                        <label style={{ color: 'white' }}>
                            <input type="checkbox" checked={audioEnabled} onChange={handleAudioToggle} /> Enable Audio
                        </label>
                        <label style={{ color: 'white', marginLeft: '10px' }}>
                            <input type="checkbox" checked={videoEnabled} onChange={handleVideoToggle} /> Enable Video
                        </label>
                        <label style={{ color: 'white', marginLeft: '10px' }}>
                            <input type="checkbox" checked={screenSharing} onChange={(e) => handleScreenShareToggle(e.target.checked)} /> Share Screen
                        </label>
                    </div>
                </div>
                {remoteVideos}
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={audioEnabled} onChange={handleAudioToggle} /> Enable Audio
                </label>
                <label>
                    <input type="checkbox" checked={videoEnabled} onChange={handleVideoToggle} /> Enable Video
                </label>
            </div>
        </div>
    );
};

export default WebRTCClient;
