import { Footer } from "./controls/footer"
import { Header } from "./controls/header"
import { Main } from "./controls/main"

export const LayoutStandardPage = ({component}:{component:string}) => {

    return(
        <div className="w-100 h-100 position-absolute">
            <Header />
            <Main component={component}/>
            <Footer />
        </div>
    )
}