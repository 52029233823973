const { REACT_APP_RTC_BASEURL, REACT_APP_RTC_PATH, REACT_APP_API_BASEURL, REACT_APP_API_PATH } = process.env;

export const RTCEndpoint = REACT_APP_RTC_BASEURL || "";
export const RTCPath = REACT_APP_RTC_PATH || "";

export const APIEndpoint = REACT_APP_API_BASEURL || "";
export const APIPath = REACT_APP_API_PATH || "";

export const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    
};

export const headersMulti = {
    'Content-Type': 'multipart/form-data',
    
};


export const handleLogging = (input:any) => {
    //console.log(input);
}