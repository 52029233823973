import { PartialChannels } from "../partial/PartialChannels";

export const Main = ({component}:{component:string}) => {

    const renderPartial = (component:string) => {
        switch(component){
            
            case PartialChannels.toString():{
                return <PartialChannels />;
            }
            
            default: {
                return null;
            }
        }
    }
    return(
        <div>
            {renderPartial(component)}
        </div>
    )
}